import React, { Suspense, useState, useEffect } from 'react';
import { Tabs, TabList, Tab, TabPanel } from '@zendeskgarden/react-tabs';
import Loader from '../../components/Loader';
import SendMessageUi from '../send-message';
import { useLocation } from 'react-router-dom';
import TemplateList from './templateList';
import { useBackend } from '../../api/BackendApiService';

function TemplateViewer({ layoutService }) {
  const { clientData, getTemplates, authData } = useBackend();
  const [selectedTab, setSelectedTab] = useState('sendMessageTab');
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const {
    state: { tab, showNotification, clearTemplate },
  } = useLocation();

  useEffect(() => {
    if (tab) setSelectedTab(tab);
    else setSelectedTab('viewTemplatesTab');
    let headerData = {
      clientData,
    };
    if (showNotification) {
      headerData['type'] = 'success';
      headerData['title'] = 'Template Submitted';
      headerData['body'] = 'Your template has been submitted to Meta and may take a few minutes to be approved.';
      headerData['timer'] = 10000;
    }
    layoutService.setHeaderDataForPage(headerData);
  }, [tab, clearTemplate]);

  useEffect(() => {
    //TODO : To be managed afterwards
    if (selectedTab === 'sendMessageTab') {
      if (selectedTemplate) {
        getTemplates(selectedTemplate?.language, authData?.channel?.channelId);
        return;
      }
      getTemplates(authData?.channel?.templateDefaultLanguage, authData?.channel?.channelId);
    }
  }, [selectedTab]);

  const onSendClick = (template) => {
    setSelectedTab('sendMessageTab');
    setSelectedTemplate(template);
  };

  return (
    <div className="grid max-w-[500px] -mx-1">
      <div>
        <Tabs
          selectedItem={selectedTab}
          onChange={(tab) => {
            setSelectedTab(tab);
            layoutService.setHeaderDataForPage({ clientData });
            setSelectedTemplate(null);
          }}
        >
          <TabList className="max-w-[310px] ml-5">
            <Tab item="sendMessageTab">Send Message</Tab>
            <Tab item="viewTemplatesTab">View Templates</Tab>
          </TabList>
          <Suspense fallback={<Loader />}>
            <TabPanel item="sendMessageTab">
              <SendMessageUi selectedTemplate={selectedTemplate} />
            </TabPanel>
            <TabPanel item="viewTemplatesTab">
              <TemplateList tab={selectedTab} onSendClick={onSendClick} layoutService={layoutService} />
            </TabPanel>
          </Suspense>
        </Tabs>
      </div>
    </div>
  );
}

export default TemplateViewer;
