import React, { useState, lazy, Suspense, memo, useEffect } from 'react';
import { Tabs, TabList, Tab, TabPanel } from '@zendeskgarden/react-tabs';
import Loader from '../components/Loader';
import { no_user } from '../api/ZendeskApiService';
import { Mixpanel } from '../components/MixPanel';
import { MixPanelAppAccess } from './send-message/constants';
import { useBackend } from '../api/BackendApiService';

const AgentActivity = lazy(() => import('../components/AgentActivity'));
const BulkSummary = lazy(() => import('../components/BulkSummary'));
const AgentSummary = lazy(() => import('../components/AgentSummary'));

const SummaryTabs = memo(() => {
  const [selectedTab, setSelectedTab] = useState('tab-1');
  const [clientData, setClientData] = useState({ user: { role: 'admin' } });
  const { authData, setChannels, getChannels, setNotification } = useBackend();
  const isAdmin = clientData.user?.role === 'admin';

  useEffect(() => {
    async function fetchClientData() {
      const data = await no_user();
      setClientData(data);
      Mixpanel.track(MixPanelAppAccess, {
        User: data?.user?.email,
        Domain: data?.account?.subdomain,
        AppLocation: data?.location,
      });
    }
    fetchClientData();
    setSelectedTab(null);
    setSelectedTab(localStorage.getItem('selectedTab') ?? 'tab-1');
  }, []);

  useEffect(() => {
    if (authData) fetchChannels();
  }, [authData]);

  const fetchChannels = () => {
    getChannels()
      .then((res) => {
        setChannels(res.channels);
        if (res?.channels?.length === 0) {
          const notificationObject = {
            title: 'No channels available.',
            description: 'Please contact your Zendesk account admin or open your zendesk sidebar for more info.',
            mode: 'info',
            hideClose: true,
          };
          setNotification(notificationObject);
        } else if (!res.channels.find((channel) => channel.isDefault)) {
          const notificationObject = {
            title: 'Setup not complete',
            description: 'Please contact your admin to complete the installation from the side bar.',
            mode: 'info',
            hideClose: () => {
              return clientData?.user && clientData?.user === 'admin' ? false : true;
            },
          };
          setNotification(notificationObject);
        }
      })
      .catch((error) => {
        let description = 'Check your internet connection and refresh widget to try again';
        let title = 'Couldn’t get Channels';
        if (error.status === 402) {
          title = 'Trial Expired';
          description = 'Please buy a license to continue';
        } else if (error.status === 401 || error.status === 403) {
          description = error.responseJSON.message;
          if (error.responseJSON.code === 'AccessDenied') {
            title = 'Unable to access';
            description = `Oops! Seems like you do not have access for Chat Inc Proactive. Please request your admin (${error.responseJSON.data.adminEmail}) to grant you access.`;
          }
        } else if (error.status < 500) {
          description = error.responseJSON.message;
        } else description = 'Internal Server Error! Please report this error to ChatInc @ zendesk@chatinc.com.';
        const notificationObject = {
          title: title,
          description: `${description} (${authData?.auth?.domain})`,
          mode: 'error',
          hideClose: true,
        };
        setNotification(notificationObject);
      });
  };

  return (
    <Tabs
      selectedItem={selectedTab}
      onChange={(tab) => {
        localStorage.setItem('selectedTab', tab);
        setSelectedTab(tab);
      }}
    >
      <TabList>
        <Tab item="tab-1">My Activity</Tab>
        {isAdmin && (
          <>
            <Tab item="tab-2">Agent Send Summary</Tab>
            <Tab item="tab-3">Bulk Send Summary</Tab>
          </>
        )}
      </TabList>
      <Suspense fallback={<Loader />}>
        <TabPanel item="tab-1">
          <div style={{ maxWidth: 'fit-content' }}>
            <div style={{ margin: '0px 25px' }}>{<AgentActivity myActivity={true} tab={selectedTab} />}</div>
          </div>
        </TabPanel>
        {isAdmin && (
          <>
            <TabPanel item="tab-2">
              <AgentSummary tab={selectedTab} />
            </TabPanel>
            <TabPanel item="tab-3">
              <BulkSummary tab={selectedTab} />
            </TabPanel>
          </>
        )}
      </Suspense>
    </Tabs>
  );
});

export default SummaryTabs;
