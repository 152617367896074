import React, { Suspense, useEffect, useState } from 'react';
import TemplateCarousel from './template-carousel';
import TemplatePreview from '../../components/TemplatePreview';
import TextEditor from './text-editor';
import { Button } from '@zendeskgarden/react-buttons';
import { useLocation, useNavigate } from 'react-router-dom';
import { Tabs, TabList, Tab, TabPanel } from '@zendeskgarden/react-tabs';
import Loader from '../../components/Loader';
import Language from '../send-message/language';
import { htmlToPlainText, parseHtmlText } from '../send-message/template/templateAdapter';
import AlertNotifcation from '../../components/notification/alert-notification';
import { useBackend } from '../../api/BackendApiService';
import Channel from '../../components/channels';
import { MAX_MESSAGE_LENGTH } from '../template-wizards/constant';
import { getVariablesFromText } from './text-editor/textEditorAdapter';
import CampaignName from '../../components/campaignName';

function CreateTemplate({ layoutService }) {
  const { getSampleTemplates, addTemplate, channels, authData, getAllLanguages, clientData } = useBackend();
  const [selectedTab, setSelectedTab] = useState(null);
  const [editTemplate, setEditTemplate] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [duplicateSelectedTemplate, setDuplicateSelectedTemplate] = useState(null);
  const [temporaryTemplate, setTemporaryTemplate] = useState(null);
  const [templateText, setTemplateText] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [alertNotification, setAlertNotification] = useState(null);
  const [templateName, setTemplateName] = useState(null);
  const [isSuccess, setIsSuccess] = useState(true);
  const [sampleTemplates, setSampleTemplates] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [selectedChannel, setSelectedChannel] = useState(null);
  const navigate = useNavigate();
  //const {state: { template } = { template: null }} = useLocation();
  const location = useLocation() || {};
  const { state } = location;
  const { template } = state || {};


  useEffect(() => {
    fetchSampleTemplates();
    getAllLanguages();
    if (template) {
      onSelectedTemplateChange(template, true);
    }
    setSelectedChannel(channels.find((item) => item.id === authData?.channel?.id));
    layoutService.setHeaderDataForPage({
      clientData,
      type: 'info',
      title: 'Important',
      body: 'Templates can take up to an hour to be approved by Meta',
      location: 'templateWizard',
    });
  }, []);

  const fetchSampleTemplates = async () => {
    const response = await getSampleTemplates();
    if (response) {
      setSampleTemplates(response);
      !template && onNewTemplateSelect(response[0]);
    }
  };

  const updateSelectedTemplate = (component, value) => {
    const newTemplate = duplicateSelectedTemplate;
    if (component === 'BODY') {
      let text = parseHtmlText(value).text;
      const bodyComponent = newTemplate?.components.find((ele) => ele?.type === 'BODY');
      if (bodyComponent) {
        bodyComponent.text = htmlToPlainText(text);
      }
    } else if (component === 'BUTTONS') {
      const buttonsComponent = newTemplate?.components.find((ele) => ele?.type === 'BUTTONS');
      if (buttonsComponent) buttonsComponent.buttons = value;
      else if (!!value?.length) newTemplate?.components?.push({ type: 'BUTTONS', buttons: value });
    } else if (component === 'templateName') {
      newTemplate.name = value.toLowerCase().replace(/\s+/g, '_');
      newTemplate.friendlyName = value;
    } else if (component === 'language') {
      newTemplate.language = value;
    } else if (component === 'variables') {
      if (newTemplate?.variables?.hasOwnProperty('body')) {
        newTemplate.variables.body = value?.length;
      } else {
        newTemplate.variables = { body: value?.length };
      }
      const bodyComponent = newTemplate?.components.find((ele) => ele?.type === 'BODY');
      const allValues = value.reduce((acc, obj) => {
        // Extract values from the object and concatenate them with the accumulator
        return acc.concat(Object.values(obj));
      }, []);
      bodyComponent.example.body_text[0] = allValues;
    }
    checkSubmitCondition();
  };

  const checkSubmitCondition = () => {
    const buttonsComponent = duplicateSelectedTemplate?.components.find((ele) => ele?.type === 'BUTTONS');
    const bodyComponent = duplicateSelectedTemplate?.components.find((ele) => ele?.type === 'BODY');
    if (buttonsComponent && buttonsComponent?.buttons?.length > 0) {
      const hasEmptyOrNullText = buttonsComponent?.buttons.some((button) => !button?.text);
      if (hasEmptyOrNullText) {
        setIsSuccess(false);
        return;
      }
    }
    if (duplicateSelectedTemplate?.variables?.body > 0) {
      const hasEmptyOrNullText = bodyComponent?.example?.body_text[0]?.some((value) => !value);
      if (hasEmptyOrNullText) {
        setIsSuccess(false);
        return;
      }
    }
    if (!duplicateSelectedTemplate?.name?.length) {
      setIsSuccess(false);
      return;
    }

    if (!duplicateSelectedTemplate.language) {
      setIsSuccess(false);
      return;
    }
    if (bodyComponent?.text?.length > MAX_MESSAGE_LENGTH) {
      setIsSuccess(false);
      return;
    }
    setIsSuccess(true);
  };

  const onTabChange = (tab) => {
    setSelectedTab(tab);
  };

  const onSelectedTemplateChange = (template, editable = false) => {
    const bodyComponent = template?.components.find((ele) => ele?.type === 'BODY');
    if (!bodyComponent?.hasOwnProperty('example')) {
      const variablesInText = getVariablesFromText(bodyComponent?.text);
      let bodyText = [];
      variablesInText.map((item) => bodyText.push(''));
      bodyComponent['example'] = { body_text: [bodyText] };
    }
    setSelectedTemplate(template);
    setSelectedTab('preview');
    setEditTemplate(editable);
    setTemplateName(template?.friendlyName);
    setSelectedLanguage(template?.language);
    editable && setDuplicateSelectedTemplate(JSON.parse(JSON.stringify(template)));
  };

  const onNewTemplateSelect = (template) => {
    if (editTemplate) {
      setAlertNotification(true);
      setTemporaryTemplate(template);
      return;
    }
    onSelectedTemplateChange(template);
  };

  const onAlertButtonClick = (text) => {
    if (text.toLowerCase() === 'discard') {
      onSelectedTemplateChange(JSON.parse(JSON.stringify(temporaryTemplate || selectedTemplate)));
      setDuplicateSelectedTemplate(null);
      setTemporaryTemplate(null);
    }
    setAlertNotification(false);
  };

  const AlertButtons = [
    {
      text: 'Cancel',
      onClick: onAlertButtonClick,
    },
    {
      text: 'Discard',
      onClick: onAlertButtonClick,
      color: '#CC3340',
    },
  ];

  const onChannelChange = (item) => {
    setSelectedChannel(item);
  };

  const onChangeTemplateName = (value) => {
    const name = value;
    const specialCharsPattern = /[^\w\s]/g;
    const nameWithoutSpecialChar = name.replace(specialCharsPattern, '');
    setTemplateName(nameWithoutSpecialChar);
    updateSelectedTemplate('templateName', name);
  };

  const createTemplate = async () => {
    setSubmitting(true);
    const template = JSON.parse(JSON.stringify(duplicateSelectedTemplate || selectedTemplate));
    template?.components?.forEach((component, idx) => {
	    console.log("example length:: ", component?.example?.length);
      //if (!component?.example?.length) delete component.example;

	    console.log("example:: ", component?.example);
      console.log("type of example:: ", typeof component?.example);
      console.log("example length:: ", component?.example?.length);

      if (component.type === 'BODY' && component.example && component.example.body_text) {
        const bodyTextValue = component.example.body_text;
        delete component.example;

        component.example = bodyTextValue[0];
        return;
      }

      if (component.type === 'BUTTONS' && component.buttons.length === 0) {
        template.components.splice(idx, 1);
      }
    });
    const payload = {
      channelId: selectedChannel?.id?.toString(),
      name: template?.name,
      friendlyName: template?.friendlyName,
      components: template?.components,
      category: template?.category,
      language: template?.language,
    };
    try {
      const response = await addTemplate(payload);
      setSubmitting(false);
      navigate('/template-viewer', { state: { showNotification: true } });
    } catch (error) {
      console.log('Error : ', error);
      setSubmitting(false);
    }
  };

  return (
    <div className="grid max-w-[500px] -mx-1">
      <div className="grid gap-3 mx-3 mt-4">
        <span className="text-[14px]">Choose example from the library below</span>
        <div className={`${selectedTab === 'edit' && 'bg-[#F8F9F9] -mx-3 mt-4 py-3 flex place-content-center'}`}>
          <TemplateCarousel
            selectedTemplate={selectedTemplate}
            onTemplateSelect={onNewTemplateSelect}
            sampleTemplates={sampleTemplates}
          />
        </div>
        {selectedTemplate && (
          <>
            <div className="-mx-3">
              <Tabs selectedItem={selectedTab} onChange={(tab) => onTabChange(tab)}>
                <TabList className={`${editTemplate ? 'max-w-[190px]' : 'max-w-0'} mx-3`}>
                  <Tab item="preview">Preview</Tab>
                  {editTemplate && <Tab item="edit">Edit</Tab>}
                </TabList>
                <Suspense fallback={<Loader />}>
                  {selectedTab === 'preview' && (
                    <TabPanel item="preview" className="mx-3">
                      <TemplatePreview
                        components={editTemplate ? duplicateSelectedTemplate?.components : selectedTemplate?.components}
                        variables={editTemplate ? duplicateSelectedTemplate?.variables : selectedTemplate?.variables}
                        highlightTags={true}
                        setTemplateText={setTemplateText}
                        selectedTemplate={editTemplate ? duplicateSelectedTemplate : selectedTemplate}
                      />
                    </TabPanel>
                  )}
                  {editTemplate && selectedTab === 'edit' && (
                    <TabPanel item="edit">
                      <TextEditor
                        selectedTemplate={duplicateSelectedTemplate}
                        templateText={templateText}
                        updateSelectedTemplate={updateSelectedTemplate}
                      />
                    </TabPanel>
                  )}
                </Suspense>
              </Tabs>
            </div>
            <div className={`grid mt-2 -mx-3 px-3 pt-4 ${selectedTab === 'edit' && 'bg-[#F8F9F9]'}`}>
              {editTemplate && (
                <div className="flex justify-between gap-4 items-center">
                  <CampaignName
                    label="Name your template"
                    templateName={templateName}
                    onChangeTemplateName={onChangeTemplateName}
                    tooltip="Enter template name"
                    fontSize="14px"
                    color="#2F3941"
                  />
                  <Language
                    selectedLanguage={selectedLanguage}
                    onChangeLanguage={(item) => {
                      setSelectedLanguage(item);
                      updateSelectedTemplate('language', item);
                    }}
                    showAllLanguage={true}
                    fontSize="14px"
                    label="Template Language"
                  />
                </div>
              )}
              {channels.filter((ele) => ele.isSetup).length > 1 && (
                <div className="mt-3">
                  <Channel
                    onChannelChange={onChannelChange}
                    selectedChannel={selectedChannel}
                    lable="Save to WhatsApp number"
                    fontSize="14px"
                    tooltip="Templates are connected to WhatsApp channels"
                  />
                </div>
              )}
              <hr className="mt-5 text-[#E5E7EB]"></hr>
            </div>
            <div className="flex gap-2 justify-end my-5">
              <span
                className={`p-3 text-[15px] self-center text-[#1f73b7] ${
                  submitting ? 'cursor-not-allowed' : 'cursor-pointer'
                }`}
                onClick={() => {
                  if (!submitting) {
                    if (!editTemplate) {
                      setEditTemplate(true);
                      setSelectedTab('edit');
                      setDuplicateSelectedTemplate(JSON.parse(JSON.stringify(selectedTemplate)));
                    } else {
                      setAlertNotification(true);
                    }
                  }
                }}
              >
                {editTemplate ? 'Cancel' : 'Edit this'}
              </span>
              <Button isPrimary onClick={() => createTemplate()} disabled={(editTemplate && !isSuccess) || submitting}>
                {submitting ? (
                  <span className="text-[15px]">Submitting...</span>
                ) : (
                  <span className="text-[15px]">Submit to Meta</span>
                )}
              </Button>
            </div>
          </>
        )}
      </div>
      {alertNotification && (
        <AlertNotifcation
          title="Discard Changes?"
          type="error"
          body="Editing another template before submitting your current one will overwrite it."
          buttons={AlertButtons}
          notification={alertNotification}
          setNotification={setAlertNotification}
        />
      )}
    </div>
  );
}

export default CreateTemplate;
